exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js?export=default" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js?export=default" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-agile-consulting-index-js": () => import("./../../../src/pages/agile-consulting/index.js?export=default" /* webpackChunkName: "component---src-pages-agile-consulting-index-js" */),
  "component---src-pages-coaching-index-js": () => import("./../../../src/pages/coaching/index.js?export=default" /* webpackChunkName: "component---src-pages-coaching-index-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js?export=default" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-digital-transformation-index-js": () => import("./../../../src/pages/digital-transformation/index.js?export=default" /* webpackChunkName: "component---src-pages-digital-transformation-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js?export=default" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outsourcing-index-js": () => import("./../../../src/pages/outsourcing/index.js?export=default" /* webpackChunkName: "component---src-pages-outsourcing-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js?export=default" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-development-index-js": () => import("./../../../src/pages/product-development/index.js?export=default" /* webpackChunkName: "component---src-pages-product-development-index-js" */)
}



exports.head = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js?export=head" /* webpackChunkName: "component---src-pages-404-jshead" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js?export=head" /* webpackChunkName: "component---src-pages-about-us-index-jshead" */),
  "component---src-pages-agile-consulting-index-js": () => import("./../../../src/pages/agile-consulting/index.js?export=head" /* webpackChunkName: "component---src-pages-agile-consulting-index-jshead" */),
  "component---src-pages-coaching-index-js": () => import("./../../../src/pages/coaching/index.js?export=head" /* webpackChunkName: "component---src-pages-coaching-index-jshead" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js?export=head" /* webpackChunkName: "component---src-pages-cookies-jshead" */),
  "component---src-pages-digital-transformation-index-js": () => import("./../../../src/pages/digital-transformation/index.js?export=head" /* webpackChunkName: "component---src-pages-digital-transformation-index-jshead" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js?export=head" /* webpackChunkName: "component---src-pages-index-jshead" */),
  "component---src-pages-outsourcing-index-js": () => import("./../../../src/pages/outsourcing/index.js?export=head" /* webpackChunkName: "component---src-pages-outsourcing-index-jshead" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js?export=head" /* webpackChunkName: "component---src-pages-privacy-policy-jshead" */),
  "component---src-pages-product-development-index-js": () => import("./../../../src/pages/product-development/index.js?export=head" /* webpackChunkName: "component---src-pages-product-development-index-jshead" */)
}

